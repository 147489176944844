import React from 'react'
import Masthead from "../components/masthead"

export default function datenschutz() {
    return (
        <div>
            <Masthead/>
            <section className ="page-section bg-light">
            <div className="container">
                <h1 className="section-heading">Datenschutzerklärung</h1>
                <h2 className="section-heading">Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</h2>
                <p>
                    Walter Knoch
                </p>
                <p>
                    Lindenstraße 8
                </p>
                <p>
                    96484 Meeder
                </p>
                <h3>Ihre Betroffenenrechte</h3>
                <p>
                    Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:
                </p>
                <ul>
                    <li>
                        Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),
                    </li>
                    <li>
                        Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),
                    </li>
                    <li>
                        Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),
                    </li>
                    <li>
                        Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),
                    </li>
                    <li>
                        Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und
                    </li>
                    <li>
                        Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).
                    </li>
                </ul>
                <p>
                    Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.
                    Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige Behörde.
                    Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter:  

                </p>
                <p>
                    https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</p>
                <h3>Erfassung allgemeiner Informationen beim Besuch unserer Website</h3>
                <h4>Art und Zweck der Verarbeitung:</h4>
                <p>
                    Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht registrieren oder anderweitig Informationen übermitteln, werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers, Ihre IP-Adresse und ähnliches.
                </p>
                <p>
                    Sie werden insbesondere zu folgenden Zwecken verarbeitet:
                </p>
                <ul>
                    <li>
                        Sicherstellung eines problemlosen Verbindungsaufbaus der Website,
                    </li>
                    <li>
                        Sicherstellung einer reibungslosen Nutzung unserer Website,
                    </li>
                    <li>
                        Auswertung der Systemsicherheit und -stabilität sowie
                    </li>
                    <li>
                        zur Optimierung unserer Website.                        
                    </li>

                </ul>
                <p>
                    Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Informationen dieser Art werden von uns ggfs. anonymisiert statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu optimieren.
                </p>
                <h4>
                    Rechtsgrundlage und berechtigtes Interesse:
                </h4>
                <p>
                    Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilität und Funktionalität unserer Website.
                </p>
                <h4>
                    Empfänger:
                </h4>
                <p>
                    Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter tätig werden.
                </p>
                <h4>
                    Speicherdauer:
                </h4>
                <p>
                    Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist für die Daten, die der Bereitstellung der Website dienen, grundsätzlich der Fall, wenn die jeweilige Sitzung beendet ist.
                </p>
                <p>
                    Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens 14 Tagen der Fall. Eine darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer anonymisiert, sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.
                </p>
                <h4>
                    Bereitstellung vorgeschrieben oder erforderlich:
                </h4>
                <p>
                    Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Ohne die IP-Adresse ist jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder eingeschränkt sein. Aus diesem Grund ist ein Widerspruch ausgeschlossen.
                </p>
                <h3>
                    Cookies
                </h3>
                <p>
                    Bei Cookies handelt es sich um kleine Dateien, welche beim Besuch von einigen Websites auf Ihrem Endgerät gespeichert werden.
                    Diese Website verwendet keine Cookies. Wir schalten auf dieser Seite keine Werbung und verwenden keine Tracking-Cookies.
                </p>
                <h3>
                    Kontaktformular
                </h3>
                <h4>
                    Art und Zweck der Verarbeitung:
                </h4>
                <p>
                    Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen Kommunikation mit Ihnen gespeichert. Hierfür ist die Angabe einer validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient der Zuordnung der Anfrage und der anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist optional.
                </p>
                <h4>
                    Rechtsgrundlage:
                </h4>
                <p>
                    Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf der Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO).
                    Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine unkomplizierte Kontaktaufnahme ermöglichen. Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert.
                    Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die Verarbeitung der in das Kontaktformular eingegebenen Daten zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b DSGVO).
                </p>
                <h4>
                    Empfänger:
                </h4>
                <p>
                    Empfänger der Daten sind ggf. Auftragsverarbeiter.
                </p>
                <h4>
                    Speicherdauer:
                </h4>
                <p>
                    Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage gelöscht.
                    Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den gesetzlichen Aufbewahrungsfristen nach HGB und löschen Ihre Daten nach Ablauf dieser Fristen.
                </p>
                <h4>
                    Bereitstellung vorgeschrieben oder erforderlich:
                </h4>
                <p>
                    Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir können Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.
                </p>
                <h3>
                    Verwendung von Scriptbibliotheken (Google Webfonts)
                </h3>
                <p>
                    Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf dieser Website „Google Web Fonts“ der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; nachfolgend „Google“) zur Darstellung von Schriften.
                    Weitere Informationen zu Google Web Fonts finden Sie unter
                    https://developers.google.com/fonts/faq und in der Datenschutzerklärung von Google https://www.google.com/policies/privacy/

                </p>
                <h3>
                    Eingebettete YouTube-Videos
                </h3>
                <p>
                    Auf unserer Website betten wir YouTube-Videos ein. Betreiber der entsprechenden Plugins ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA (nachfolgend „YouTube“). Die YouTube, LLC ist einer Tochtergesellschaft der Google LLC, 1600 Amphitheatre Pkwy, Mountain View, CA 94043, USA (nachfolgend „Google“). Wenn Sie eine Seite mit dem YouTube-Plugin besuchen, wird eine Verbindung zu Servern von YouTube hergestellt. Dabei wird YouTube mitgeteilt, welche Seiten Sie besuchen. Wenn Sie in Ihrem YouTube-Account eingeloggt sind, kann YouTube Ihr Surfverhalten Ihnen persönlich zuzuordnen. Dies verhindern Sie, indem Sie sich vorher aus Ihrem YouTube-Account ausloggen.
                    Wird ein YouTube-Video gestartet, setzt der Anbieter Cookies ein, die Hinweise über das Nutzerverhalten sammeln.
                    Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch YouTube erhalten Sie in den Datenschutzerklärungen des Anbieters, Dort erhalten Sie auch weitere Informationen zu Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre (https://policies.google.com/privacy).
                </p>
                <h4>
                    Widerruf der Einwilligung:
                </h4>
                <p>
                    Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen Opt-out oder ein Blockieren der Datenübertragung angeboten. Wenn Sie eine Nachverfolgung Ihrer Aktivitäten auf unserer Website verhindern wollen, widerrufen Sie bitte im Cookie-Consent-Tool Ihre Einwilligung für die entsprechende Cookie-Kategorie oder alle technisch nicht notwendigen Cookies und Datenübertragungen. In diesem Fall können Sie unsere Website jedoch ggfs. nicht oder nur eingeschränkt nutzen.
                </p>
                <h3>
                    SSL-Verschlüsselung
                </h3>
                <p>
                    Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
                </p>
                <h3>
                    Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO
                </h3>
                <h4>
                    Einzelfallbezogenes Widerspruchsrecht
                </h4>
                <p>
                    Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.
                    Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
                </p>
                <h4>
                    Empfänger eines Widerspruchs datenschutz@wkc-vianova.de
                </h4>
                <h3>
                    Änderung unserer Datenschutzbestimmungen
                </h3>
                <p>
                    Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.
                </p>
                <h3>
                    Fragen an den Datenschutzbeauftragten
                </h3>
                <p>
                    Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation:
                    Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den Experten für externe Datenschutzbeauftragte (Version #2020-09-30).

                </p>
            </div>
        </section>
        </div>
    )
}
