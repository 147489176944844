import React from 'react'
import Masthead_2 from "../components/masthead2";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Carousel } from 'react-responsive-carousel';


import berlin from "../assets/img/header/berlin.jpg";
import munich from "../assets/img/header/munich.jpg";
import coburg from "../assets/img/header/coburg.jpg";


export default function OurStory() {
    return (
        <div>
          <Carousel infiniteLoop useKeyboardArrows autoPlay showStatus={false} showThumbs={false} transitionTime="1000">
                <div>
                    <img src={berlin} />
                </div>
                <div>
                    <img src={munich} />
                </div>
                <div>
                    <img src={coburg} />
                </div>
            </Carousel>
            {/* <Masthead_2/> */}
            <section className="page-section bg-green" id="kriseff">
          <div className="container">
            <div className="text-lg-left">
                <h2 className="section-heading text-center">Our Story</h2>
                  <p>
                    Ein 19-jähriger Medizintechnik Student aus Berlin sagte mir im September 2020. Herr Knoch, sie müssen einen „Wisspod“-Podcast machen. Der Gedanke hat mir sehr gefallen und meine Begeisterung kannte keine Grenzen. Auch deshalb, weil ich meine Vorstellung aus 2012, ein Buch „Die Geschichte meiner Zukunft“ zu schreiben, bisher nicht umgesetzt habe. Ja nun, Johnny hat mir bei der Umsetzung der Internetseite und dem Aufbau des Podcast sehr geholfen und hat mich unendlich inspiriert. Wir werden den Podcast demnächst hier starten.
                  </p>
                  <p>
                  <a href="https://www.triljen.com" className="link-text">triljen</a> GmbH  hat ein <a href ="https://hej.triljen.com/hubfs/podcast/005 Knoch Walther Krise.mp3?hsCtaTracking=1bb66ab8-d850-4e63-a437-d47126264a05%7Cd158fc1f-3a45-44bd-8056-f3d70fd63350">Podcast </a> zum Thema Krise mit mir aufgezeichnet. Vielen Dank an triljen GmbH und Joachim Schwichtenberg für dieses Interview.

 
                  </p>
                  {/* <h2>
                    Die Geschichte meiner Zukunft, kann auch die Geschichte Ihrer Zukunft sein, denn in der Zukunft werden wir leben, ... um was geht es?
                  </h2>
                  <h3> EIGENVERANTWORTUNG</h3>
                  <p> ist verankert in den Grundgedanken unserer sozialen Marktwirtschaft</p>
                  <h3> FREMDVERANTWORTUNG</h3>
                  <p>für „Andere“, Personen und Umstände zu fühlen und zu handeln</p>
                  <h3>VERANTWORTUNG</h3>  
                  <p> das steht für Tun und Lassen</p>  
                  <h3>   SOLITARITÄT </h3>
                  <p> gemeinsame Werte, Zusammenhalt</p>
                  <h3>  KRISEN</h3>
                  <p>Angst, Hilflosigkeit, Rückzug, Hoffnung, Impuls für Veränderung</p>
                  <h3>WIRTSCHAFTSKRISEN von 1973 bis heute</h3>
                  <p>Zusammenhänge? Vorhersehbarkeit? Lehren daraus? Weiterwicklung?</p> */}
            </div>
          </div>
        </section>
        {/* <section className="krise">
          <div className="krise-timeline">
            <ul id='timeline'>
              <li className='work'>
                <input className='radio' id='work5' name='works' type='radio' checked>
                <div className="relative">
                  <label for='work5'>Die Geschichte meiner Zukunft</label>
                  <span className='date'>2020</span>
                  <span className='circle'></span>
                </div>
                <div className='content'>
                  <p>
                    Meine Generation– die Generation vor der Generation X - die „Baby Boomer“, man nannte die Geburtsjahre 1955 und 1956 auch die goldenen Jahrgänge, die bisher keine Kriege im üblichen Sinne in Europa erleben mussten. Halt, ich muss mich verbessern, gemeint ist westlich des 15 Längengrades, da liegt Görlitz, die östlichste Stadt Deutschlands und 1200 km westlich, da liegt London. ….man könnte auch sagen in den letzten 75 Jahren ein wohlbehütetes Gebiet in Europa.
                  </p>
                </div>
              </li>
              <li className='work'>
                <input className='radio' id='work4' name='works' type='radio' checked>
                <div className="relative">
                  <label for='work4'>Lorem ipsum dolor sit amet</label>
                  <span className='date'>12 May 2013</span>
                  <span className='circle'></span>
                </div>
                <div className='content'>
                  <p>
                    
                  </p>
                </div>
              </li>
            </ul>

                  </div>
                  <ul id='timeline-krise'>
                    <li className='work'>
                      <input className='radio-krise' id='work10' name='works' type='radio' checked>
                      <div className="relative">
                        <label for='work10'></label>
                          <span className='date-krise'>2020</span>
                          <span className='circle-krise'></span>
                      </div>
                      <div className='content-krise'>
                        <p>
                          Meine Generation– die Generation vor der Generation X - die „Baby Boomer“, man nannte die Geburtsjahre 1955 und 1956 auch die goldenen Jahrgänge, die bisher keine Kriege im üblichen Sinne in Europa erleben mussten. Halt, ich muss mich verbessern, gemeint ist westlich des 15 Längengrades, da liegt Görlitz, die östlichste Stadt Deutschlands und 1200 km westlich, da liegt London. ….man könnte auch sagen in den letzten 75 Jahren ein wohlbehütetes Gebiet in Europa.
                        </p>
                      </div>
                    </li>
                    <li className='work'>
                      <input className='radio-krise' id='work9' name='works' type='radio' checked>
                      <div className="relative">
                        <label for='work9'>Wirtschaftskrise zur Covid-19</label>
                          <span className='date-krise'>2020</span>
                          <span className='circle-krise'></span>
                      </div>
                      <div className='content-krise'>
                        <p>
                          Die dritte und folgenschwerste Pandemie im 21. Jahrhundert führt zu einer schwersten, weltweiten Wirtschaftskrise, deren Entwicklung im Januar 2021 noch nicht absehbar ist. Den Fluggesellschaften, der Eventbranche, der Gastronomie und vielen mehr wird die Existenzgrundlage entzogen. Das Gesundheitswesen kollabiert in einigen Bereichen. Was tut sich NEUES auf?
                        </p>
                      </div>
                    </li>
                    <li className='work'>
                      <input className='radio-krise' id='work8' name='works' type='radio'>
                      <div className="relative">
                        <label for='work8'>Eurokrise</label>
                          <span className='date-krise'>2010</span>
                          <span className='circle-krise'></span>
                      </div>
                      <div className='content-krise'>
                        <p>
                          Ist die Namensnennung richtig? Der Außenwert des Euro hatte keinen Wertverlust. Was ist passiert?Nach einem Boom der GIIPS-Staaten mit hohen Kapitalzuflüssen bei gleichzeitig hohen Kapitalabflüssen Deutschlands, verbunden mit hoher Arbeitslosigkeit in den Jahren 2003-2007 kam auch im Zuge der Wirtschafts- und Finanzkrise ab 2010 zu einer enormen Staatsschuldenkrise auch mit Auswirkungen auf institutionelle Kapitalanleger.
                        </p>
                      </div>
                    </li>
                    <li className='work'>
                      <input className='radio-krise' id='work7' name='works' type='radio'>
                      <div className="relative">
                        <label for='work7'>Finanz und Wirtschaftskrise</label>
                        <span className="date-krise">2007-2009</span>
                        <span className='circle-krise'></span>
                      </div>
                      <div className='content-krise'>
                        <p>
                          Die Folgen einer Deregulierung der Finanzmärkte der US-Politik im Zusammenhang mit den Terroranschlägen am 11. September 2001. Das Fehlverhalten der Banken und Ratingagenturen. Subprime-Kredite und deren Verteilung auf den deutschen Steuerzahler. „To big to fail“ stand nicht für Verantwortung. Die Regulierung danach, was wurde übersehen, wenn man nach vielen Jahren Wirecard in Verbindung bringt.
                        </p>
                      </div>
                    </li>
                    <li className='work'>
                      <input className='radio-krise' id='work6' name='works' type='radio'>
                      <div className="relative">
                        <label for='work6'>Platzen der Dotcom-Blase und die Folgen von 9/11</label>
                        <span className='date-krise'>2000-2003</span>
                        <span className='circle-krise'></span>
                      </div>
                      <div className='content-krise'>
                        <p>
                          Erwartungen an die Wirtschaft an die digitale Revolution der Computer- und Internetbranche führte zur Gründung vieler Firmen „Neuer Markt“ egal wie schwindlig die Ideen waren. Utopische Businesspläne wurden erstellt, Werbeausgaben lagen bei mehrfachen des Umsatzes. Der Beginn der „Amazon story“. Unterschiede zu anderen Krisen. Kann man eine Verbindung zur Corona Krise ableiten? Auch hier ähnliche Entwicklung an den Börsen erkennbar, u.a. Tesla.
                        </p>
                      </div>
                    </li>
                    <li className='work'>
                      <input className='radio-krise' id='work5' name='works' type='radio'>
                      <div className="relative">
                        <label for='work5'>Krisen der 90er Jahre/ Japankrise</label>
                        <span className='date-krise'>1991</span>
                        <span className='circle-krise'></span>
                      </div>
                      <div className='content-krise'>
                        <p>
                          Volkswirtschaften in Südamerika, Asien und Russland standen vor dem Zusammenbruch. Heute sind die Auswirkungen auf 30 Jahre Niedrigzinspolitik Japans bekannt. Die nordische Bankenkrise bereiteten Schweden und Finnland erhebliche Probleme. Die Pfund Krise 1992 führte zum Zusammenbruch des Europäischen Währungssystem (EWS). Dank der Wiedervereinigung wird Deutschland von diesen Krisen verschont. In der Zeit 1995 bis 1997 entstand der Europäische Stabilitäts- und Wachstumspakt unter Führung Deutschlands, aber auch Deutschland hat die Konvergenz-Kriterien mit der Einführung des Euros nicht erfüllt.
                        </p>
                      </div>
                    </li>
        
                    <li className='work'>
                      <input className='radio-krise' id='work3' name='works' type='radio'>
                      <div className="relative">
                        <label for='work3'>US-Sparkassen-Krise</label>
                        <span className='date-krise'>1980</span>
                        <span className='circle-krise'></span>
                      </div>
                      <div className='content-krise'>
                        <p>
                          Auch Savings-and-Loan-Krise genannt. Über 1000 Sparkassen brachen zusammen bzw. mussten vom amerikanischen Staat aufgefangen werden. Hoher Abfluss in Geldmarktfonds bei einerseits variablen Einlagenzinsen und andererseits Festzinsdarlehen führten zu erheblichen Verlusten und zur Erosion des Eigenkapitals. Deregulierung und „Moral Hazard“ waren für die erheblichen Folgen verantwortlich. Die amerikanischen Steuerzahler wurden mit rd. US$ 125 Mrd. belastetet.
                        </p>
                      </div>
                    </li>
                    <li className='work'>
                        <input className='radio-krise' id='work2' name='works' type='radio'>
                        <div className="relative">
                          <label for='work2'>zweite Öl-Krise</label>
                          <span className='date-krise'>1979-1980</span>
                          <span className='circle-krise'></span>
                        </div>
                        <div className='content-krise'>
                          <p>
                            Nachdem die persische Monarchie unter Schah Mohammad Reza Pahlavi durch die islamische Revolution beendet wurde und der Irak dem Iran den Krieg erklärte entstand eine erneute Verknappung der Ölfördermenge am Weltmarkt. Neue Ressourcen, u.a. die Nordsee-Ölförderung wurden erschlossen.
                          </p>
                        </div>
                      </li>
                      <li className='work'>
                      <input className='radio-krise' id='work1' name='works' type='radio'>
                      <div className="relative">
                        <label for='work1'>erste Öl-Krise</label>
                        <span className='date-krise'>1973-1974</span>
                        <span className='circle-krise'></span>
                      </div>
                      <div className='content-krise'>
                        <p>
                          Der andauernde Nahostkonflikt ist am 06. Oktober 1973 eskaliert. Am höchsten jüdischen Feiertag Jom Kippur, verübten Ägyptische und Syrische Truppen einen Angriff auf die von Israel besetzten Gebiete der Golanhöhen und Sinai. Bereits nach 2 Wochen konnten der Sicherheitsrat der vereinten Nation einen Waffenstillstand bewirken. Der Öl-Preis stieg innerhalb eines Tages um 60%. Fahrverbote an vier Sonntagen wurden im November und Dezember 1973 erlassen.
                        </p>
                      </div>
                    </li>
                  </ul>
                    </div>
            </div>
          </div>
        </section> */}
        </div>
    )
}
