import { useState } from 'react';
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const ContactForm = () => {
  const { register, errors, handleSubmit, reset } = useForm();

  const toastifySuccess = () => {
    toast('Nachricht gesendet!', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: 'submit-feedback success',
      toastId: 'notifyToast'
    });
  };

  const onSubmit = async (data) => {
    // Send form email
    try {
      const templateParams = {
        name: data.name,
        email: data.email,
        subject: data.subject,
        message: data.message
      };
      await emailjs.send(
        "service_i8smxyn",
        "template_6tikc6q",templateParams,
        "user_Xx7rFA7tnOKRtyLKYhEgx"
      );

      reset();
      toastifySuccess();
    } catch (e) {
      console.log(e);
    }
  };

  return (

      <div className='ContactForm' id="contact">
      <div class="container">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">Kontakt</h2>
                </div>
                <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>
                    <div class="row align-items-stretch mb-5">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input
                                    type='text'
                                    name='name'
                                    ref={register({
                                    required: { value: true, message: 'Bitte geben Sie Ihren Namen ein' },
                                    maxLength: {
                                    value: 30,
                                    message: '30 Buchstaben oder weniger'
                                    }
                                    })}
                                    className='form-control formInput'
                                    placeholder='Name'
                                ></input>
                                {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
                            </div>
                            <div class="form-group">
                                <input
                                type='email'
                                name='email'
                                ref={register({
                                    required: true,
                                    pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                                })}
                                className='form-control formInput'
                                placeholder='Email Adresse'
                                ></input>
                                {errors.email && (
                                <span className='errorMessage'>Bitte geben Sie eine gültige Email Adresse ein</span>
                                )}
                            </div>
                            <div class="form-group mb-md-0">
                                <input
                                    type='text'
                                    name='subject'
                                    ref={register({
                                        required: { value: true, message: 'Bitte geben Sie ein Betreff an' },
                                        maxLength: {
                                        value: 75,
                                        message: 'Subject cannot exceed 75 characters'
                                        }
                                    })}
                                    className='form-control formInput'
                                    placeholder='Betreff'
                                    ></input>
                                    {errors.subject && (
                                <span className='errorMessage'>{errors.subject.message}</span>
                                    )}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group form-group-textarea mb-md-0">
                                <textarea
                                    rows={3}
                                    name='message'
                                    ref={register({
                                        required: true
                                    })}
                                    className='form-control formInput'
                                    placeholder='Nachricht'
                                    ></textarea>
                                    {errors.message && <span className='errorMessage'>Bitte geben Sie eine Nachricht ein</span>}
                            </div>
                        </div>
                    </div>
                    <div class="text-center">
                        <div id="success"></div>
                        <button class="btn btn-primary btn-xl text-uppercase" id="sendMessageButton" type="submit">Senden</button>
                    </div>
                </form>
            </div>
    </div>
                                    

  );
};

export default ContactForm;
