import React from 'react'

export default function Masthead() {
    return (
        <div>
            <header className="masthead">
            <div className="container">
                <div className="masthead-subheading">wkc-</div>
                <div className="masthead-heading ">vianova</div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
            </div>
        </header>
        </div>
    )
}
