import './App.css';

import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';
import Branchen from "./pages/branchen";
import Datenschutz from "./pages/datenschutz";
import Impressum from './pages/impressum';
import Landing from "./pages/landing";
import OurStory from "./pages/ourstory";
import About from "./pages/about";
import Vianova from "./pages/vianova";

import Navbar from "./components/navbar";
import Footer from "./components/footer";
import NotFound from "./components/notfound";

import useScript from "react-script-hook";
import Notfound from './components/notfound';
import ScrollToTop from "./components/scrolltotop"

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div>
      <Navbar/>
        <Switch>
          <Route exact path ="/">
            <About />
          </Route>
          <Route exact path ="/ueber-uns">
            <About/>
          </Route>
          <Route exact path ="/branchen">
            <Branchen />
          </Route>
          <Route exact path ="/vianova">
            <Vianova />
          </Route>
          <Route exact path ="/ourstory">
            <OurStory />
          </Route>
          <Route exact path ="/impressum">
            <Impressum />
          </Route>
          <Route exact path ="/datenschutz">
            <Datenschutz />
          </Route>
          <Route component={Notfound}></Route>
        </Switch>
        <Footer/>
        
      </div>
      
    </Router>
  );
}

export default App;
