import React from 'react'
import { NavLink } from 'react-router-dom';
import Scroll from 'react-scroll'
const ScrollLink = Scroll.ScrollLink


export default function Navbar() {
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
            <div className="container">
                <a className="navbar-brand js-scroll-trigger">
                    <NavLink to = "/" >
                    WKC-VIANOVA
                    </NavLink> 
                </a>
                <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    Menü
                    <i className="fas fa-bars ml-1"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav text-uppercase ml-auto">
                        <li className="nav-item">
                            <NavLink to ="/" className="nav-link js-scroll-trigger" activeClassName="active">
                                Über uns
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to ="/branchen" className="nav-link js-scroll-trigger" activeClassName="active">
                                Branchen
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to ="/vianova" className="nav-link js-scroll-trigger" activeClassName="active">
                                Vianova
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to ="/ourstory" className="nav-link js-scroll-trigger" activeClassName="active">
                                Our Story
                            </NavLink>
                        </li>
                        <li>
                            <ScrollLink
                                to="contact-destination" 
                                spy={true} 
                                smooth={true} 
                                duration={500} 
                                className='nav-link js-scroll-trigger' 
                                activeClass='active'
                            >
                                Kontakt

                            </ScrollLink>
                        </li>
                        
                    </ul>
                </div>
            </div>
            </nav>
        </div>
    )
}
