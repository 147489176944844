import React from 'react'
import humancircle from "../assets/img/about/humancircle.jpg";
import boot from "../assets/img/about/boot.png";
import knoch from "../assets/img/about/knoch.jpg";
import philosophy from "../assets/img/about/philosophy.jpg";
import solution from "../assets/img/about/solution.jpg";
import handmoon from "../assets/img/about/handmoon.jpg";
import eurocrisis from "../assets/img/about/eurocrisis.jpg";
import yes from "../assets/img/about/yes.jpg";
import children from "../assets/img/about/children.jpg";

import Masthead from "../components/masthead";
import ContactForm from "../components/confactform";

export default function about() {
    return (
        <div>
            <Masthead/>
            <section className="page-section" id="about">
            <div className="container">
                <div className="text-center">
                    <h3 className=" font-italic">wkc-vianova ist...</h3>
                </div>

                    <ul className="timeline">
                        <br></br>
                        <br></br>
                        <br></br>
                        <li>
                            <div className="timeline-image"><img className="rounded-circle img-fluid" src={humancircle} alt="" /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                </div>
                                <div className="timeline-body">
                                    <p className="text-muted">
                                        eine Unternehmensberatung, die auf der Basis eines humanistischen Menschenbildes arbeitet. Wir wollen, dass Menschen im Umgang miteinander an Größe gewinnen und erfolgreich werden.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li className="timeline-inverted">
                            <div className="timeline-image"><img className="rounded-circle img-fluid" src={knoch} alt="" /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                </div>
                                <div className="timeline-body">
                                    <p className="text-muted">
                                        Walter Knoch, Inhaber der wkc-vianova sieht sich nicht nur als „Banker “ bzw. langjähriger Angestellter einer Privatbank, in der er über viele Jahre abwechslungsreiche, verantwortliche Aufgaben übernommen hat, sondern in erster Linie als Mensch, Unternehmer und Berater mit langjähriger Erfahrung in mehreren Branchen.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="timeline-image"><img className="rounded-circle img-fluid" src={philosophy} alt="" /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4>Unsere Philosophie</h4>
                                </div>
                                <div className="timeline-body">
                                    <p className="text-muted">
                                        Mit Leidenschaft bei der Sache, vertrauensvolle Beziehungen, Augenmerk auf die jeweilige Marktsituation sowie Ausdauer und Flexibilität sind unser Selbstverständnis. Wir verstehen uns als Partner unserer Kunden.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li className="timeline-inverted">
                            <div className="timeline-image"><img className="rounded-circle img-fluid" src={boot} alt="" /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                </div>
                                <div className="timeline-body">
                                    <p className="text-muted">
                                        Wir Leben und Lieben was wir tun. Dabei steht die ganzheitliche Sichtweise unter Berücksichtigung der Belange des Unternehmers und des Unternehmens im Mittelpunkt der Lösungsfindung und der Bewältigung von Krisen 
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="timeline-image"><img className="rounded-circle img-fluid" src={yes} alt="" /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4>Unsere wesentlichen</h4>
                                    <h4 className="subheading">Tätigkeiten</h4>
                                </div>
                                <div className="timeline-body">
                                    <p className="text-muted">
                                        Erstellung von Analysen und Konzepten zur Betriebsoptimierung, 
                                    </p>
                                    <p className="text-muted">
                                        Beratung über traditionelle und alternative Finanzierungsoptionen, 
                                    </p>
                                    <p className="text-muted">
                                        die Beurteilung und Organisation der Markt- und Kundenausrichtung, 
                                    </p>
                                    <p className="text-muted">
                                        die Begleitung im Veränderungsprozess und Unterstützung in Krisen, 
                                    </p>
                                    <p className="text-muted">
                                        Lösungsfindung in der Personalplanung und der Personalentwicklung 
                                    </p>
                                </div>
                            </div>
                        </li>
                        
                        <li className="timeline-inverted">
                            <div className="timeline-image"><img className="rounded-circle img-fluid" src={eurocrisis} alt="" /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4>Krisen-</h4>
                                    <h4 className="subheading"> Management</h4>
                                </div>
                                <div className="timeline-body">
                                    <p className="text-muted">
                                        Wir verfügen über langjährige Erfahrungen in der Bewältigung von Krisen. Praxis und Erfahrung kann man nicht „downloaden“. Wir unterstützen Sie bei der Entwicklung der Grundlagen eines Risikomanagements und geben Ihnen Hilfestellung um eine Krise erfolgreich zu bewältigen und Chancen daraus zu generieren.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="timeline-image"><img className="rounded-circle img-fluid" src={solution} alt="" /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4>Was wir mitbringen</h4>
                                    <h4 className="subheading"> und was wir wollen</h4>
                                </div>
                                <div className="timeline-body">
                                    <p className="text-muted">
                                        Neben der Einbringung von fachlichem Know-how und einem umfangreichen Erfahrungsschatz bringen wir für Unternehmen und Unternehmer Verständnis für die Ist-Situation mit und suchen nach Lösungen. Wir unterstützen Sie, um Ihre zukunftsträchtigen Ideen und die Machbarkeit in Einklang zu bringen. Im Idealfall setzen Unternehmer und das Unternehmen die erarbeitete Strategie langfristig aus eigener Kraft um.
                                    </p>
                                </div>
                            </div>
                        </li>
                        
                        <li className="timeline-inverted">
                            <div className="timeline-image"><img className="rounded-circle img-fluid" src={handmoon} alt="" /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4>Was wir nicht wollen</h4>
                                    <h4 className="subheading"> und nicht können</h4>
                                </div>
                                <div className="timeline-body">
                                    <p className="text-muted">
                                        Gegenseitige Abhängigkeiten schaffen und in Interessenskonflikte verwickelt werden. Aus diesem Grund übernehmen wir keine Kredit- und Darlehensvermittlungen. Mitwirken an "großen Träumen und außergewöhnlichen Luftschlössern", die wohl nicht zu verwirklichen sind.
                                    </p>
                                </div>
                            </div>
                        </li>
                        
                        <li>
                            <div className="timeline-image"><img className="rounded-circle img-fluid" src={children} alt="" /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4>Erklärung und</h4>
                                    <h4 className="subheading"> Hinweis</h4>
                                </div>
                                <div className="timeline-body">
                                    <p className="text-muted">
                                        Wir distanzieren uns von Rassismus, Faschismus und anderen subversiven Entwicklungen, ebenso ist bei uns kein Platz für politischen und religiösen Extremismus 
                                    </p>
                                </div>
                            </div>
                        </li>
                        <br></br>
                        
                    </ul>
    
                </div>
                
            </section>
            <section className="contact">
                <ContactForm/>
            </section>
        </div>
    )
}

