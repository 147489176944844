import React from 'react'
import Masthead_3 from "../components/masthead3"

export default function vianova() {
    return (
        <div>
            <Masthead_3/>
            <section className ="page-section bg-green" id="vianova">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2 className="text-uppercase section-heading">Vianova</h2>

                        <p className="text-about text-lg-left">
                            Neben der bekannten Via Nova, einer Verbindungstraße vom Palatin zum Velabrum in Rom ist die Via Nova ein in 2005 eröffneter europäischer Pilgerweg, der alten Wallfahrtsrouten folgt und die Länder Tschechien, Bayern und Österreich verbindet. Kurz vor seinem Ende in der Nähe des Wolfgangsees in Österreich trifft er auf den Jakobsweg, der dann weiter nach Rom und Santiago de Compostela führt. Der <a href="https://www.pilgerweg-vianova.eu/">europäische Pilgerweg</a> Via Nova stellt eine spirituelle Wegweisung im 21. Jahrhundert dar:
                        </p>
                        <p className="text-about">
                            <strong>vianova</strong>  ... ist auch für uns eine Wegweisung und steht bei uns für 
                        </p>
                        <p><strong>V</strong>ision, Stärke</p>
                        <p><strong>I</strong>nternationalität</p>
                        <p><strong>A</strong>chtung, Respekt </p>
                        <p><strong>N</strong>euem aufgeschlossen</p>
                        <p><strong>O</strong>ffenheit und Ehrlichkeit </p>
                        <p><strong>V</strong>ertrauen und Verlässlichkeit</p>
                        <p><strong>A</strong>ufbruchstimmung und Neuanfang</p>
                        <p className="text-about">
                            An dieser Wegweisung wollen wir uns messen lassen. Jede Krise birgt Zukunftschancen um neue Wege zu gehen, Lösungen zu finden und diese mit uns erfolgreich umzusetzen.
                        </p>
                        <p className="text-taetigkeiten">
                            Albert Einstein formulierte einmal folgende Aussage:
                        </p>
                        <p className="text-taetigkeiten">
                            "Mehr als die Vergangenheit interessiert mich die Zukunft, denn in ihr gedenke ich zu leben"
                         </p>
                    </div>
                </div>
            </div>
        </section>
        </div>
    )
}
