import React from 'react'
import {Link} from "react-router-dom"
export default function NotFound() {
    return (
        <div>
            <h1>404 - Seite nicht gefunden</h1>
            <Link to ="/">
                zurück
            </Link>
        </div>
    )
}
