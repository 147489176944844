import React from 'react'
import { Link } from 'react-router-dom';
export default function Footer() {
    return (
        <div>
            <footer className="footer py-4">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 text-lg-left">Copyright © Knoch 2021</div>
                    <div className="col-lg-4 my-3 my-lg-0">
                    </div>
                    <div className="col-lg-4 text-lg-right">
                        <Link to ="/datenschutz" className="mr-3">Datenschutz</Link>
                        <Link to ="/impressum">Impressum</Link>
                    </div>
                </div>
            </div>
        </footer>
        </div>
    )
}
