import React from 'react'
import Masthead_2 from '../components/masthead2'

import bakery from "../assets/img/branchen/bakery.jpg";
import energy from "../assets/img/branchen/windrad.jpg";
import immobilien from "../assets/img/branchen/immo.jpg";
import emergency from "../assets/img/branchen/risiko.jpg";
import bank from "../assets/img/branchen/bank.jpg";
import health from "../assets/img/branchen/kranken.jpg";

import close from "../assets/img/close-icon.svg";

function Branchen() {
    return (
        <div>
            
            <section className="page-section bg-apricot" id="portfolio">
                <div className="container">
                    <div className="text-center">
                        <br></br>
                        <br></br>
                        <h2 className="section-heading text-uppercase">Branchen & Leistungen</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-sm-6 mb-4">
                            <div className="portfolio-item">
                                <a className="portfolio-link" data-toggle="modal" href="#portfolioModal1">
                                    <div className="portfolio-hover">
                                    <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                                    </div>
                                <img className="img-fluid" src={bank} width="500" height="200" alt="" />
                                </a>
                            <div className="portfolio-caption">
                                <div className="portfolio-caption-heading">Finance &</div>
                                <div className="portfolio-caption-heading">Banking</div>
                                <br></br>
                                <div className="portfolio-caption-subheading text-muted">
                                    Die Finanzwirtschaft (Finanzierung und Investitionen) ist das Gegenstück zur Realwirtschaft (Gütermärkte). Man kann diese Teilwirtschaften innerhalb einer Marktwirtschaft nicht trennen. Die Finanzmärkte unterscheiden sich in die Teilbereiche Geldmarkt bzw. Kapitalmarkt und Devisenmarkt. 
                                </div>
                                <br></br>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-toggle="modal" href="#portfolioModal2">
                                <div className="portfolio-hover">
                                    <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                                </div>
                                <img className="img-fluid" src={immobilien} height="200"  alt="" />
                            </a>
                            <div className="portfolio-caption">
                                <div className="portfolio-caption-heading">Betriebs-</div>
                                <div className="portfolio-caption-heading">Immobilien</div>
                                <br></br>
                                <div className="portfolio-caption-subheading text-muted">
                                    Immobilien haben für viele Unternehmen und Unternehmer eine wesentliche Bedeutung. In der Regel benötigen mittelständische Unternehmen Immobilien um eine unternehmerische Tätigkeit auszuüben. Oftmals sind der operative Betrieb und die Betriebsimmobilie in einer rechtlichen Einheit.
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-toggle="modal" href="#portfolioModal5">
                                <div className="portfolio-hover">
                                    <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                                </div>
                                <img className="img-fluid" src={bakery} alt="" />
                            </a>
                            <div className="portfolio-caption">
                                <div className="portfolio-caption-heading">Handel &</div>
                                <div className="portfolio-caption-heading">Handwerk</div>
                                <br></br>
                                <div className="portfolio-caption-subheading text-muted">
                                    Handel & Handwerk haben bereits im Mittelalter durch Austausch von Waren und Dienstleistungen zum Wohlstand geführt. Die stark gewachsene Weltbevölkerung in den letzten 100 Jahren und das enorme Wachstum der Städte sind letztendlich dem Welthandel -Beschaffung und Absatz - zu verdanken. 
                                </div>
                                <br></br>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0">
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-toggle="modal" href="#portfolioModal4">
                                <div className="portfolio-hover">
                                    <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                                </div>
                                <img className="img-fluid" src={energy} alt="" />
                            </a>
                            <div className="portfolio-caption">
                                <div className="portfolio-caption-heading">Energie & </div>
                                <div className="portfolio-caption-heading">Umwelt</div>
                                <br></br>
                                <div className="portfolio-caption-subheading text-muted">
                                    Der globale Ressourcen- und Energieverbrauch steigt unvermindert an. Hierzu trägt sowohl das weltweite Bevölkerungswachstum als auch die Zunahme des Wohlstandes der Nationen bei. Um unseren Wohlstand zu erhalten sind wir alle aufgefordert ressourcenunabhängiges Wirtschaftswachstum zu schaffen.
                                </div>
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4 mb-sm-0">
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-toggle="modal" href="#portfolioModal6">
                                <div className="portfolio-hover">
                                    <div className="portfolio-hover-content">
                                        <i className="fas fa-plus fa-3x"></i></div>
                                </div>
                                <img className="img-fluid" src={emergency} alt="" />
                            </a>
                            <div className="portfolio-caption">
                                <div className="portfolio-caption-heading">Risiko- &</div>
                                <div className="portfolio-caption-heading">Krisenmanagement</div>
                                <br></br>
                                <div className="portfolio-caption-subheading text-muted">
                                    Risikomanagement schafft Werte, ist integrierter Teil von Organisationsprozessen. Kontinuitätsmanagement und Notfallplanung sicher die Handlungsfähigkeit von Unternehmen. Integrität und Teamfähigkeit, Kommunikation mit Vertrauen und ehrlichem Respekt ist die beste Antwort auf die Komplexität von Krisen. 
                                </div>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-toggle="modal" href="#portfolioModal3">
                                <div className="portfolio-hover">
                                    <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                                </div>
                                <img className="img-fluid" src={health} alt="" />
                            </a>
                            <div className="portfolio-caption">
                                <div className="portfolio-caption-heading">Health-</div>
                                <div className="portfolio-caption-heading">Care</div>
                                <br></br>
                                <div className="portfolio-caption-subheading text-muted">
                                    Die Gesundheitswirtschaft ist der größte Wirtschaftszweig aller Industriestaaten. Für die BRD hat die Gesundheitswirtschaft einen erheblichen ökonomischen Anteil an der Gesamtwirtschaft. Bei einer weitgefassten Definition der Gesundheitswirtschaft sind in Deutschland rd. 7,7 Mio. Menschen beschäftigt. 
                                </div>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    

        <div className="portfolio-modal modal fade" id="portfolioModal1" tabindex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal"><img src={close} alt="Close modal" /></div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="modal-body">
                        
                                    <h2 className="text-uppercase">Finance &Banking</h2>
                                    
                                    <img className="img-fluid d-block mx-auto" src={bank} alt="" />
                                    <p className="text-lg-left">
                                        Wir verfügen über langjährige, praktische Erfahrungen in allen Bereichen der Unternehmensfinanzierung, auch bei Spezial- und Sonderfinanzierungen, u.a. Bauträger- und Finanzierungen im gesamten Gesundheitswesen. Gerne unterstützen wir Sie auch bei alternativen Finanzierungen, wie Leasing, Factoring, Fördermitteldarlehen, Mezzanine Darlehen sowie bei Wachstumsfinanzierungen, MBO-Finanzierungen bis hin zu strukturierten Finanzierungen. Wir weisen ausdrücklich darauf hin, dass wir keine Kredit- und Darlehensvermittlungen übernehmen, da wir uns nicht in Interessenskonflikte verwickelt sehen möchten.
                                    </p>
                                    <p className="text-lg-left">
                                        Jede Unternehmensfinanzierungsstruktur muss das Geschäftsmodell abbilden. 
                                    </p>
                                    <p className="text-lg-left">
                                        Neben der Unternehmensfinanzierungsberatung sehen wir uns in die Lage versetzt das Regelwerk der Banken (Kreditwesengesetz) verständlich zu machen. Wir können Funktionsweisen, wie Losgrößen-, Fristen-, Risiko- und Publizitätstransformationen erklären und die Entwicklung von Basel 1 bis Basel 4 erläutern. Die Zielsetzung der Bankenregulierung dient in erster Linie dem Anlegerschutz. Mit dem Verstehen der volkswirtschaftlichen und betriebswirtschaftlichen Notwendigkeiten der Regulierung können wir Kreditvergaben für Unternehmen nachvollziehbar zu machen.
                                    </p>
                                
                                <button className="btn btn-primary" data-dismiss="modal" type="button">
                                    <i className="fas fa-times mr-1"></i>
                                    Schließen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        
        <div className="portfolio-modal modal fade" id="portfolioModal2" tabindex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="close-modal" data-dismiss="modal"><img src={close} alt="Close modal" /></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="modal-body">
                                
                                <h2 className="text-uppercase">Immobilien</h2>
                                <img className="img-fluid d-block mx-auto" src={immobilien} alt="" />
                                <p className="text-lg-left">
                                    Wir begleiten Sie bei allen betriebswirtschaftlichen und finanziellen Überlegungen rund um Ihre Betriebsimmobilie, insbesondere beim Erwerb, der Erweiterung, dem Rückbau, der Modernisierung und der Umschuldung.
                                </p>
                                <p className="text-lg-left">
                                    Unsere Erfahrungen haben gezeigt, dass trotz enormer Wertsteigerungen von Betriebsimmobilien in manchen Regionen, auch bedingt durch die Niedrigzinsphase, die Bestandteile des operativen Betriebsvermögens nicht immer optimal aufgestellt sind.                                
                                </p>   
                                <p className="text-lg-left">
                                    Bei der Unternehmens- und Betriebsimmobilienfinanzierung achten wir auf die Haftungsmasse auch im Zusammenhang mit Ihrem Privatvermögen. Wir erläutern Ihnen die Thematiken die mit dem notwendigen Rating bei Banken entstehen können.
                                </p>
                                <p className="text-lg-left"> 
                                    Auch bei einer Unternehmensnachfolge kann in der Regel die Immobilie und der Betrieb nicht getrennt übergeben werden. Wir empfehlen daher allen Unternehmern, die mit dieser Thematik noch nicht vertraut sind, sich rechtzeitig an ihre Anwälte, Steuerberater und Wirtschaftsprüfern zu wenden und sich diesbezüglich zu erkundigen und beraten zu lassen.      
                                </p>       
                                <p className="text-lg-left">
                                    Wir weisen ausdrücklich darauf hin, dass wir keinerlei Maklertätigkeiten übernehmen, da wir uns nicht in Interessenskonflikte verwickelt sehen möchten.
                                </p>
                                    <button className="btn btn-primary" data-dismiss="modal" type="button">
                                        <i className="fas fa-times mr-1"></i>
                                        Schließen
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="portfolio-modal modal fade" id="portfolioModal3" tabindex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal"><img src={close} alt="Close modal" /></div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="modal-body">
                                    
                                    <h2 className="text-uppercase">Health-Care</h2>
                                    
                                    <img className="img-fluid d-block mx-auto" src={health} alt="" />
                                    <p className="text-lg-left ">
                                        Die vielfältigen Tätigkeiten in diesem Bereich haben zu unserer Erkenntnis geführt, dass das Finden und Halten der Mitarbeiter und Mitarbeiterinnen im Gesundheitswesen und vor allem in der Pflege das Erfolgskonzept für Wachstum, Erfolg und medizinische Qualität ist.
                                    </p>
                                    <p className="text-lg-left">
                                        Wir verfügen über langjährige Erfahrungen im Finanzierungsbereich des Gesundheitswesens. Allen voran bei „start ups“ von niedergelassenen Ärzten und Ärztinnen, Alten- und Pflegeheimen, Krankenhäusern sowie in der Medizintechnik und der Pharmaindustrie.
                                    </p>                
                                    <p className="text-lg-left">
                                        Wir haben Zugriff auf ein breites Netzwerk in allen Bereichen des Gesundheitswesens und unterstützen Sie in Ihren Investitionsüberlegungen und in allen betriebswirtschaftlichen Fragestellungen sowie der Nachfolgeregelung.
                                    </p>   
                                    <button className="btn btn-primary" data-dismiss="modal" type="button">
                                        <i className="fas fa-times mr-1"></i>
                                        Schließen
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="portfolio-modal modal fade" id="portfolioModal4" tabindex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal"><img src={close} alt="Close modal" /></div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="modal-body">
                                    
                                    <h2 className="text-uppercase">Energie & Umwelt</h2>
                                    <img className="img-fluid d-block mx-auto" src={energy} alt="" />
                                    <p className="text-lg-left">
                                        Umweltbewusste Unternehmensführung wird als Wettbewerbsfaktor immer wichtiger. Unternehmerische Tätigkeit ist mit Umwelt- Sicherheits- und Haftungsrisiken verbunden. Präventive Maßnahmen zur Reduzierung dieser Risiken halten wir zur Sicherung des Geschäftserfolg für unerlässlich.
                                    </p>
                                    <p className="text-lg-left">
                                        Bei der notwendigen und sinnvollen Durchführung von energetischen Maßnahmen unterstützen wir bei der Antragstellung für öffentliche Darlehen und Zuschüsse (u.a. BAMF, KfW und Lfa) und beraten bei der Strukturierung der Finanzierung und der Finanzierungssicherheit..                                    
                                    </p>   
                                    <p className="text-lg-left">
                                        Diskutieren Sie mit uns und unseren Partnern die Potentiale im Bereich der Energieeffizienz und deren betriebswirtschaftlichen Auswirkungen in Ihrem Unternehmen. Unsere Netzwerkpartner, mit wissenschaftlichem Hintergrund, sind berechtigt Energieaudits sowie Bewertungen von Energieeffizienzmaßnahmen nach EEffg durchzuführen.
                                    </p>
                                    <button className="btn btn-primary" data-dismiss="modal" type="button">
                                        <i className="fas fa-times mr-1"></i>
                                        Schließen
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="portfolio-modal modal fade" id="portfolioModal5" tabindex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal"><img src={close} alt="Close modal" /></div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="modal-body">
                                    
                                    <h2 className="text-uppercase">Handel & Handwerk</h2>
                                    <img className="img-fluid d-block mx-auto" src={bakery} alt="" />
                                    <p className="text-lg-left">
                                        Im Handel & Handwerk haben wir die ersten unternehmerischen Erfahrungen erworben. Selbst Handwerksmeister mit dem Bewusstsein was körperliche Arbeit bedeutet.
                                    </p>
                                    <p className="text-lg-left">
                                        Wir sehen vor allem für kleinere und mittlere Betriebe die Digitalisierung als eine große Herausforderung. Entwicklungen wie die Sharing Economy haben im Handel & Handwerk Einzug gehalten. Auch das ungebremste Wachstum des E-Commerz sieht einer Sättigung entgegen, so dass der Handel vor allem in der Produktpräsentation, in der Sortimentsgestaltung und Logistik vor neuen Herausforderungen gestellt wird.
                                    </p>
                                    <p className="text-lg-left">
                                        Wir unterstützen Sie bei allen Ihren Investitionsvorhaben und bei Finanzierungsfragen. Im Rahmen Ihres Finanzcontrollings konzipieren wir Aktionspläne und unterstützen Sie bei der Realisierung von Kostenoptimierungen, verfassen für Sie Ergebnis- und Abweichungsanalysen.
                                    </p>
                                    <button className="btn btn-primary" data-dismiss="modal" type="button">
                                        <i className="fas fa-times mr-1"></i>
                                        Schließen
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="portfolio-modal modal fade" id="portfolioModal6" tabindex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal"><img src={close} alt="Close modal" /></div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="modal-body">
                                    
                                    <h2 className="text-uppercase">Risiko- und Krisenmanagement</h2>
                                    
                                    <img className="img-fluid d-block mx-auto" src={emergency} alt="" />
                                    <p className="text-lg-left">
                                        Wir sind davon überzeugt, dass Krisen auch einen Sinn haben. Wirtschaftskrisen in den vergangenen Jahrzehnten haben gezeigt, dass die Weiterentwicklung forciert wird. Veränderungen ermöglichen auch für Unternehmen neue Chancen.
                                    </p>
                                    <p className="text-lg-left">
                                        Wir halten es für sinnvoll, wenn jedes Unternehmen ein Risikopräventionssystem installiert oder sich zumindest fortlaufend Gedanken über Unternehmensrisiken und deren möglichen Auswirkungen macht. Wir unterstützen gerne bei der Installation eines Risikopräventionssystems. Ein Krisenmanagement kann immer erst beginnen, wenn eine Krise eingetreten ist und wenn klar ist ob ihr das Unternehmen betroffen ist. Krisen treffen jeden, sie enden aber auch.
                                    </p>   
                                    <button className="btn btn-primary" data-dismiss="modal" type="button">
                                        <i className="fas fa-times mr-1"></i>
                                        Schließen
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default Branchen

